<template>
  <div>
    <div ref="navBar">
      <UserAdministrationNavBar />
    </div>
    <b-modal ref="updateUserStatusDialog" title="Update User Status" header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" 
      hide-header-close no-close-on-esc no-close-on-backdrop
      content-class="shadow" ok-title="Yes" @cancel="resetUserState" @ok="changeUserState">
      Are you sure you want to change user [{{ selectedActionUser.username }}] status from {{ selectedActionUser.state }}
      to {{ selectedActionUser.state == "Active" ? "Inactive" : "Active" }}?
    </b-modal>
    <b-badge class="w-100 overflow-hidden" variant="info" show size="sm">
      Normals</b-badge
    >
    <b-container class="mt-2" fluid>
      <b-tabs pills vertical>
        
          <b-form >
                       
            <b-row class="mt-2" align-h="between">
              <b-col>
                <b-row>
                  <b-col class="px-1" cols="auto"
                    ><b-button @click="loadAllNormals" title="Click to list all normal"
                      variant="primary"
                      ><b-icon icon="table"></b-icon>  Normals List</b-button
                    ></b-col
                  ><b-col class="px-1" cols="auto"
                    ><b-button v-if="canUseTemplateEditor" @click="resetFilters" title="Click to create new normal"
                    ><b-icon icon="journal-bookmark"></b-icon>  Create Normal </b-button
                  ></b-col>
                  <!--
                  <b-col class="px-1" cols="auto"
                    ><b-button v-if="canUseTemplateEditor" @click="importNormals" title="Click to import normals"
                    ><b-icon icon="journal-bookmark"></b-icon>  Import Normals </b-button
                  ></b-col>
                -->
                <b-col v-if="isSuperAdmin" class="px-1">
                    <b-input-group>
                      <b-form-select plain
                        v-model="selectedCustomer"
                        :options="customerFilterOptions"
                        @change="handleCustomerFilter"
                      >
                      </b-form-select> 
                    </b-input-group>
                  </b-col>
                  <b-col class="px-1">
                    <b-input-group>
                      <b-form-select plain
                        v-model="selectedTenant"
                        :options="tenantFilterOptions"
                        @change="handleTenantFilter"
                      >
                      </b-form-select> 
                    </b-input-group>
                  </b-col>
                  <b-col class="px-1">
                    <b-input-group>
                      <b-form-select plain
                        v-model="selectedUser"
                        :options="userFilterOptions"
                        @change="handleUserFilter"
                      >
                      </b-form-select> 
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>
              
              <b-col class="px-1 text-white" cols="auto" 
                    ><b-input-group>Search By : 
                         <b-form-input
                        id = 'filterVal' 
                        placeholder="Normal Name"
                      ></b-form-input> </b-input-group
              ></b-col>
              <b-col class="px-1" cols="auto"
                    ><b-button @click="loadAllNormals" title="Search Normal Templates"
                      
                      > Search</b-button
                    ></b-col>
              </b-row>
              <b-row class="mt-4">
              <b-table 
                striped
                class="white-font"
                :fields="['','template_name','customer_name','Assigned_Tenants','Assigned_Users','Actions']"
                :items="tableFetchedUsers"
              >
                <template #table-busy>
                  <div class="text-center text-white my-2 mt-3">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(template_id)="data">
                  <div >{{ data.item[0] }}</div>
                </template>
                <template #cell(template_name)="data">
                  <div >{{ data.item[1] }}</div>
                </template>
                <template #cell(customer_name)="data">
                  <div >{{ data.item[2] }}</div>
                </template>
                <template #cell(Assigned_Tenants)="data">
                  <div >{{ data.item[3] }}</div>
                </template>
                <template #cell(Assigned_Users)="data">
                  <div >{{ data.item[4] }}</div>
                </template>
                <template #cell(actions)="row">

                
                  <template v-if="row.item.id != userId">
                    <b-button 
                    @click="editTemplate(row.item, row.index, $event.target)"
                    class="mr-1"
                    title="Edit Template"
                  >
                    <b-icon icon="pencil-square"></b-icon>
                  </b-button>
                  </template>
                              
                  <b-button
                    @click="viewTemplate(row.item, row.index, $event.target)"
                    class="mr-1"
                    title="View Template"
                  >
                    <b-icon icon="eye-fill"></b-icon>
                  </b-button>

                  <b-dropdown class="mr-1" title="Download Master Template" :size="buttonSize" right>
                    <template #button-content>
                      <b-icon icon="download"></b-icon>
                    </template>
                    <template v-if="candownloadTemplates && (row.item.id != userId)">
                      <b-dropdown-item @click="downloadTemplate(row.item)" title="Download Template as word(docx) format"
                      ><b-icon icon="file-earmark-word"></b-icon> Word</b-dropdown-item
                      >
                    </template>
                    <template v-if="candownloadTemplates && (row.item.id != userId)">
                      <b-dropdown-item @click="downloadTemplatePdf(row.item)" title="Download Template as Pdf format"
                        ><b-icon icon="file-earmark-pdf"></b-icon> Pdf</b-dropdown-item
                      >
                    </template>
                  </b-dropdown>
                </template>
              </b-table>
            </b-row>
            <template v-if="totalRows > 5">
              <div class="m-0 p-0" ref="paginationControls">
                <b-navbar type="dark" variant="dark" class="px-0">
                  <b-nav-form>
                    <b-input-group
                      :prepend="'Total: ' + totalRows + ' Show:'"
                      size="sm"
                    >
                      <b-form-select
                        v-model="perPage"
                        :options="perPageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-input-group>
                  </b-nav-form>
                  <b-navbar-nav v-if="perPage > 0" >
                    <b-pagination
                      class="ml-2"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      :limit="5"
                      :first-number="true"
                      :last-number="true"
                    >
                    </b-pagination>
                  </b-navbar-nav>
                </b-navbar>
              </div>
              
            </template>
          </b-form>
        <!--</b-tab>-->
        <!-- If more tabs added, clicking on them should navigate to a different route for the specific functionality -->
      </b-tabs>
    </b-container>

    <!-- User Modals (Delete) -->

    <UserDeleteDialogue
      :deleteUserInfo="deleteUserModal"
      :resetDeleteUserModal="resetDeleteUserModal"
      :user="selectedActionUser"
      @on-delete="reloadUser"
    />
  </div>
</template>

<script>
import permissions from "../common/permissions";
import UserAdministrationNavBar from "./UserAdministrationNavBar.vue";
import userAdministrationWebServices from "../common/userAdministrationWebServices";
import UserDeleteDialogue from "./UserDeleteDialogue.vue";
import webServices from "../common/webServices";

export default {
  name: "UserAdministration",
  components: {
    UserAdministrationNavBar,

    UserDeleteDialogue,
  },
  data() {
    return {
      perPageOptions: [
        { value: 2, text: "2" },
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 0, text: "All" },
      ],
      createTimestamp: null,
      items: [
        {
          id: 0,
          tenants: {},
          customers: {},
        },
      ],

      filters: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        customer: "",
        tenant: "",
        mobileNumber:"",
        dirty: false,   
      },
      perPage: 10,
      currentPage: 1,
      isBusy: false,
      isEdit: false,
      showModal: false,
      usernameFilter: "",
      emailFilter: "",
      firstNameFilter: "",
      lastNameFilter: "",
      customerFilter: "",
      tenantProfileFilter: "",
      status: "Inactive",
      
      tableFetchedUsers: [],
      addUserModal: {
        id: "add-user-modal",
        title: "",
        content: "",
      },
      editTemplateModal: {
        id: "edit-user-modal",
        title: "",
        content: null,
      },
      viewTemplateModal: {
        id: "clone-user-modal",
        title: "",
        content: "",
      },
      deleteUserModal: {
        id: "delete-user-modal",
        title: "",
        content: "",
      },
      filter: null,
      filterOn: [],
      selectedActionUser: {},
      allUsers:[],
      allUser: [],
      selectedTenants: {},
      tenantsOfCustomers: [],
      tenantOptions: {},
      selectedCustomer: '',
      selectedTenant: '',
      selectedUser: '',
      userFilterOptions: [],
    };
    
  },
  async mounted() {
    if (!this.isSuperAdmin) {
      this.tableFields = this.tableFields.filter((item) => item.key != "customers")
    }
try {
      await this.loadTenants();
    } catch (error) {
      this.$log.error("Error ",error)
    }
    /*
    try {
      await this.loadAllUsers();
    } catch (error) {
      this.$log.error("Error ",error)
    }
    */
    try {
      await this.loadAllNormals();
    } catch (error) {
      this.$log.error("Error ",error)
    }
    
    // Restore column search terms (SP-661).
    //
    this.filters = Object.assign(this.filters, this.$store.state.userAdminTableFilters)
    this.filterData()
  },
  computed: {
    isSuperAdmin() {
      return permissions.isSuperAdmin()
    },
    canUseTemplateEditor() {
      return permissions.isSuperAdmin() || 
        (permissions.customersForPermission(permissions.CAN_EDIT_REPORT_TEMPLATES).includes(this.$store.state.customerId)) ||
        (permissions.groupsForPermission(permissions.CAN_USE_TEMPLATE_EDITOR).length > 0)
    },
    candownloadTemplates() {
      return permissions.isSuperAdmin() ||
        (permissions.customersForPermission(permissions.CAN_DELETE_USERS).includes(this.$store.state.customerId));
    },
    totalRows() {
      return this.tableFetchedUsers.length;
    },
    filteredCustomers() {
      return this.tenantOptions;
    }, 
    fields() {
      
      var fieldDefns = [];
      if (this.items.length > 0) {
        fieldDefns.push({
          key: "_s",
          label: "",
          sortable: false,
          thClass: "bg-secondary text-white position-sticky",
        });
        fieldDefns.push({
          key: "actions",
          label: "Actions",
          sortable: false,
          thClass: "bg-secondary text-white position-sticky",
        });
        fieldDefns.push({
          key: "_l",
          label: "Lock",
          sortable: true,
          thClass: "bg-secondary text-white position-sticky",
        });
        const keys = Object.keys(this.items[0]);
        for (var f = 0; f < keys.length; f++) {
          const key = keys[f];
          if (!key.startsWith("_")) {
            fieldDefns.push({
              key: key,
              label: key,
              sortable: true,
              thClass: "bg-secondary text-white position-sticky",
            });
          }
        }
      }
      return fieldDefns;
    },
    locale() {
      return this.$store.state.locale;
    },
    userId() {
      return this.$store.state.keycloak.tokenParsed.sub;
    },
    activeComponent() {
      return this.$store.state.activeComponent;
    },
    customerFilterOptions() {
      // Return sorted list of all customer names in use.
      //
      var customerOptions = [{ value: "", text: "Filter by Customer"}]
      var customerNames = []
      try {
        this.allUser.forEach(user => {
          Object.keys(user.customers).forEach((customerId) => {
            const customerItem = this.tenantOptions.find((item) => item.id == customerId)
            if (!customerNames.includes(customerItem.name)) {
              customerNames.push(customerItem.name)
            }
          })
        })
      }
      catch(err) {
        this.$log.error(`Error parsing all customers: ${err.message}`)
      }
      customerNames.sort().forEach(name => {
        customerOptions.push({ value: name, text: name })
      })
      this.$log.debug('customerOptions')
      this.$log.debug(customerOptions)
      return customerOptions
    },
    tenantFilterOptions() {
      // Return sorted list of all customer names in use.
      //
      var tenantOptions = [{ value: "", text: "Filter by Tenant"}]
      var customerOptions = this.customerFilterOptions
      try {
        customerOptions.forEach(customer => {
          if (customer.value !== "") {
            var groupOptions = []
            var tenantNames = []
            const customerItem = this.tenantOptions.find((item)=>item.name == customer.value)
            if (customerItem) {
              customerItem.tenants.forEach(tenant => {
                tenantNames.push(tenant.text)
              })
            }
            tenantNames.sort().forEach(name => {
              let value = `${customer.value}|${name}`
              groupOptions.push({ value: value, text: name})
            })
            tenantOptions.push({ label: "", text: customer.text, options: groupOptions})
          }
        })
      }
      catch(err) {
        this.$log.error(`Error parsing all customers: ${err.message}`)
      }
      return tenantOptions
    },
  },
  watch: {
    activeComponent(newVal /*, oldVal*/) {
      this.$log.debug("changing activeComponent to [" + newVal + "]");
      if (newVal == "") {
        this.split.setSizes([100, 0]);
      } else {
        if (this.$refs.navBar.clientWidth < 600) {
          this.split.setSizes([0, 100]);
        } else if (this.split.getSizes()[0] > 90) {
          this.split.setSizes([50, 50]);
        }
        if (newVal == "UserHistory") {
          this.$refs.userHistory.show();
        }
      }
    },
    filters: {
      handler: function (newVal/*, oldVal*/) {
        this.$store.commit('changeUserAdminTableFilters', Object.assign({}, newVal));
      },
      deep: true
    },
  },
  methods: {
    filterData(){
      this.filters.dirty = false;
      this.tableFetchedUsers= this.allUsers.filter((item) => {
        let customerMatches = this.getCustomerNames(item.customers).filter(name => {return name === this.filters.customer})
        let tenantName = this.filters.tenant.substring(this.filters.tenant.indexOf('|')+1)
        let tenantMatches = this.getTenantNames(item.customers).filter(name => {return name === tenantName})
        let email = item.email || ""
        let keep = item.username.toLowerCase().includes(this.filters.username.toLowerCase()) 
        keep &= email.toLowerCase().includes(this.filters.email.toLowerCase())
        keep &= item.firstName.toLowerCase().includes(this.filters.firstName.toLowerCase())
        keep &= item.lastName.toLowerCase().includes(this.filters.lastName.toLowerCase())
        keep &= item.mobileNumber.includes(this.filters.mobileNumber)
        keep &= (this.filters.customer === '') || (customerMatches.length > 0)
        keep &= (this.filters.tenant === '') || (tenantMatches.length > 0)
        return keep
      });
    },
    async loadAllNormals() {
      try {
        this.isBusy = true;
        var tempFilter = document.getElementById("filterVal").value
        if (tempFilter == '') {
          tempFilter = "%"
        }
        var customerFilter = ''
        var tenantFilter = ''
        var userFilter = ''
        if (this.selectedCustomer != '') {
          customerFilter = this.selectedCustomer 
          customerFilter = customerFilter + '%'
        }
        else {
          customerFilter = "%"
        }
        if (this.selectedTenant != '') {
          tenantFilter = this.selectedTenant 
          tenantFilter = tenantFilter + '%'
        }
        else {
          tenantFilter = "%"
        }
        this.userFilterOptions = []
        var response = await userAdministrationWebServices.fetchAllUsers();
        this.allUser=response
        this.userFilterOptions.push('Filter by User');
        for (let i = 0; i < this.allUser.length; i++) {
          this.userFilterOptions.push(this.allUser[i]['username']);
        }
        if (this.selectedUser == '') {
          this.selectedUser = 'Filter by User'
        }
        if (this.selectedUser != '') {
          userFilter = this.selectedUser 
          userFilter = '%' + userFilter + '%'
          if (this.selectedUser == "Filter by User") {
            userFilter = '%'
          }
        }
        else {
          userFilter = "%"
        }
        response = await webServices.fetchAllNormals(tempFilter, customerFilter, tenantFilter, userFilter);
        this.tableFetchedUsers = response;
        this.allUsers=response
      } catch (error) {
        this.$log.debug("Error::", error.message);
      } finally {
        this.isBusy = false;
      }
    },
    async loadTenants() {
      try {
        const response =
          await userAdministrationWebServices.fetchAdminTenants();

        this.tenantOptions = response;
                
        if (!this.isSuperAdmin) {
          const filteredTenants = this.tenantOptions.find((item)=>item.id ==this.$store.state.customerId)
          
          this.tenantsOfCustomers[0] = filteredTenants.tenants;
          
        }
      } catch (error) {
        this.$log.error("error::", error);
      }
      const groupedPermisisons =permissions.getAvailableGroupedTenantPermissions()
      this.allGroupedPermissions=groupedPermisisons
    },
    getCustomerNames(customersObj) {
      var customerNames = []
      try {
        Object.keys(customersObj).forEach((customerId) => {
          const customerItem = this.tenantOptions.find((item) => item.id == customerId)
          const tenantsObj = customersObj[customerId].tenants
          Object.keys(tenantsObj).forEach(() => {
            customerNames.push(customerItem.name)
          })
        })
      }
      catch(err) {
        this.$log.error(`Error parsing customers: ${err.message}`)
      }
      return customerNames
    },
    getTenantNames(customersObj) {
      var tenantNames = []
      try {
        Object.keys(customersObj).forEach((customerId) => {
          const customerItem = this.tenantOptions.find((item) => item.id == customerId)
          const tenantsObj = customersObj[customerId].tenants
          Object.keys(tenantsObj).forEach((tenantId) => {
            const tenantItem = customerItem.tenants.find((item) => item.id == tenantId)
            tenantNames.push(tenantItem.text)
          })
        })
      }
      catch(err) {
        this.$log.error(`Error parsing tenants: ${err.message}`)
      }
      return tenantNames
    },
    handleCustomerFilter(/*value*/) {
      if (this.selectedCustomer != '') {
        this.selectedTenant = ''
      }
      this.filters.dirty = true;
    },
    handleTenantFilter(/*value*/) {
      if (this.selectedTenant != '') {
        this.filters.customer = this.filters.tenant.substring(0, this.filters.tenant.indexOf('|'))
        this.selectedCustomer = ''
      }
      this.filters.dirty = true;
    },
    onAddNewUser() {
      this.$router.push("/create_user");
    },
    async reloadUser() {
      this.loadAllNormals();
    },
    validateField(e)
    {
      let char = String.fromCharCode(e.keyCode);
      e = (e) ? e : window.Event;
      if(/^\p{Letter}+$/u.test(char) || /^[\d]+$/.test(char) || /^[ -]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    isLetter(e)
    {
      let char = String.fromCharCode(e.keyCode);
      if(/^\p{Letter}+$/u.test(char) || /^[ \-']+$/.test(char)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      e = (e) ? e : window.Event;
      if (/^[\d]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    changeUserStateDialog(item) {
      this.selectedActionUser = item;
      this.$refs.updateUserStatusDialog.show();
    },
    resetUserState() {
      this.selectedActionUser.state = (this.selectedActionUser.state == "Active") ? "Inactive" : "Active";
    },
    changeUserState() {
      const item = this.selectedActionUser;
      userAdministrationWebServices.changeUserStatus(item.id,item.state == "Active")
      .then(() => {
        this.$bvToast.toast(
          `Account state now ${item.state} for ${item.username}.`,
          {
            autoHideDelay: 5000,
            solid: true,
            title: "User Administration",
            variant: "success",
          }
        );
      })
      .catch(err => {
        this.$log.error(`An error occured resetting password for ${item.username}: ${err.message}`)
        this.$bvToast.toast(
          `Unable to change state for ${item.username}, contact super administrator.`,
          {
            autoHideDelay: 5000,
            solid: true,
            title: "User Administration",
            variant: "danger",
          }
        );
        item.state = (item.state == "Active") ? "Inactive" : "Active"
      })
    },
    resetFilters() {
      this.$router.push("/create_normal_template");
     },
     importNormals() {
      this.$router.push("/upload_normals");
     },
    addUser() {
      this.addUserModal.title = `Add User`;
      this.$root.$emit("bv::show::modal");
    },
    resetPwd(item) {
      let answer = window.confirm(`Do you really want to reset the password for username ${item.username}?`)
      if (answer) {
        let userInfo = {
          userid: item.id,
          username: item.username,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          password: this.generatePassword()
        }
        userAdministrationWebServices.resetPwd(userInfo)
        .then(() => {
          this.$bvToast.toast(
            `Password reset successfully and new temporary password has been sent to registered email for ${item.username}.`,
            {
              autoHideDelay: 5000,
              solid: true,
              title: "User Administration",
              variant: "success",
            }
          );
        })
        .catch(err => {
          this.$log.error(`An error occured resetting password for ${item.username}: ${err.message}`)
          this.$bvToast.toast(
            `An error occured resetting password for ${item.username}.`,
            {
              autoHideDelay: 5000,
              solid: true,
              title: "User Administration",
              variant: "danger",
            }
          );
        })
      }
    },
    //SP-610
    generatePassword() {
      var length = 8
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*_"
      var retVal = ""
      var n = charset.length
      for (var i = 0;  i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    async editTemplate(item) {
      try {
        this.isBusy = true;
        const response = await webServices.viewNormalTemplate(item[0]);
        const tenantIdTempName = response + "/edit/" + item[0] + "/" + item[4];
        this.$log.debug("PATH::", tenantIdTempName);
        this.$router.push(`/create_normal_template`);
        this.$store.state.tenantIdTempName = tenantIdTempName
      } catch (error) {
        this.$log.debug("Error::", error.message);
      } finally {
        this.isBusy = false;
      }
    },
    async viewTemplate(item) {
      try {
        this.isBusy = true;
        const response = await webServices.viewNormalTemplate(item[0]);
        const tenantIdTempName = response + "/view/" + item[0] + "/" + item[4];
        this.$log.debug("PATH::", tenantIdTempName);
        this.$router.push(`/create_normal_template`);
        this.$store.state.tenantIdTempName = tenantIdTempName
      } catch (error) {
        this.$log.debug("Error::", error.message);
      } finally {
        this.isBusy = false;
      }
    },
    async downloadTemplate(item) {
      try {
        this.isBusy = true;
        const response = await webServices.downloadNormalTemplate(item[0]);
        const templatePath = response;// + "/download/" + item[0] + "/" + item[4] + "/docx";
        this.$log.debug("PATH::", templatePath);
        this.downloadFile(templatePath)
      } catch (error) {
        this.$log.debug("Error::", error.message);
      } finally {
        this.isBusy = false;
      }
    },
    async downloadFile(path){
      await fetch(path, { method: 'GET', })
        .then(response=>{
          if(!response.ok){
            this.$log.debug("FILE::", response.statusText);
          throw new Error(response.statusText);
          }
          return response.blob();
        })
        .then(response=>{
          var fileName = path.replace(/^.*[\\/]/, '');
          this.$log.debug("FILE NAME::", fileName);
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(error =>{
          this.$log.debug("Error::", error.message);
        });
    },
    async downloadTemplatePdf(item) {
      try {
        this.isBusy = true;
        const response = await webServices.downloadNormalTemplate(item[0] + 'pdf');
        const templatePath = response;// + "/download/" + item[0] + "/pdf";
        this.$log.debug("PATH::", templatePath);
        this.downloadFile(templatePath)
      } catch (error) {
        this.$log.debug("Error::", error.message);
      } finally {
        this.isBusy = false;
      }
    },
    resetAddUserModal() {
      this.addUserModal.title = "";
    },
    resetEditUserModal() {
      this.editTemplateModal.title = "";
      this.editTemplateModal.content = "";
    },
    resetCloneUserModal() {
      this.viewTemplateModal.title = "";
      this.viewTemplateModal.content = "";
    },
    resetDeleteUserModal() {
      this.deleteUserModal.title = "";
      this.deleteUserModal.content = "";
    },
  },
};
</script>
<style scoped>
.mrTable {
  background-color: #ffdab9 !important;
}

.table {
  text-align: left;
}

.md-18 {
  font-size: 18px;
}

.cbM {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.bottomOfPage {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.pointer {
  cursor: pointer;
}

#flex {
  display: flex;
  flex-direction: row;
}

.white-font {
  color: #fff;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>

