import axios from "axios";
import Vue from "vue";
import store from "../store";

// DEFAULT WORKLIST SETTINGS
//
const DEFAULT_WORKLIST_SETTINGS = {
  "reportPhraseLut": null,
  "reportSettings": null,
  "worklistActions": [
    "viewer_full",
    "patient_history",
    "view_report"
  ],
  "worklistColumns": [
    "actions",
    "lock",
    "patient_name",
    "study_date",
    "patient_id",
    "patient_name",
    "patient_age_sex",
    "acc_num",
    "study_desc",
    "images",
    "referring_physician",
    "reported_by",
    "study_time",
    "tat"
  ],
  "worklistSettings": {
    "Today": {
      "default": true,
      "studyDateFilter": "today"
    }
  },
  "worklistAltViewers": [],
  "worklistRefreshInterval": 5,
  "worklistViewers": {
    "dbl_click_route": "viewer",
    "lite_quality": 70
  },
  "wwwlFavorites": null,
};

//SP-610
function generatePassword() {
  var length = 8
  var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*_"
  var retVal = ""
  var n = charset.length
  for (var i = 0;  i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

// Webservice for user Admim
async function fetchAllUsers() {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(store.state.customerId);
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        let users = [];
       
        Object.keys(data).forEach((key) => {         
          users.push({ id: key, ...data[key], state: data[key].enabled ? 'Active' : 'Inactive'});
        });
        resolve(users);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function fetchAdminTenants() {
  return new Promise((resolve, reject) => {
    let url = store.state.webServicesBaseUrl + "/Tenants/admin/" + encodeURIComponent(store.state.customerId);
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        let allTenants = [];
        Object.keys(data).forEach((key) => {
          let parent = { id: key, name: data[key].name, tenants: [] };
          let tenants = [];
          Object.keys(data[key].tenants).forEach((tenantKey) => {
            tenants.push({
              value: tenantKey,
              text: data[key].tenants[tenantKey],
              id: tenantKey,
            });
          });
          parent.tenants = tenants;
          allTenants.push(parent);
        });
        resolve(allTenants);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function createUser(data) {
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(store.state.customerId);

    axios
      .post(endpoint, data, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function fetchUserById(id) {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(store.state.customerId) + "/" + encodeURIComponent(id);
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// Read user Id for customer
function getUserIds(customerId, userId) {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/Ids/" + encodeURIComponent(customerId) + "/" + encodeURIComponent(userId);
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function resetPwd(data)
{
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + "/Users/admin/password/" + encodeURIComponent(store.state.customerId) + "/" + encodeURIComponent(data.userid);
    axios
      .put(endpoint, data, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function updateUser(data) {
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(store.state.customerId) + "/" + encodeURIComponent(data.id);

    axios
      .put(endpoint, data, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function deleteUser(id) {
  return new Promise((resolve, reject) => {
    let usersUrl = store.state.webServicesBaseUrl + "/Users/admin/" + encodeURIComponent(store.state.customerId) + "/" + encodeURIComponent(id);
    axios
      .delete(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function fetchUserSettings(userId, customerId) {
  return new Promise((resolve, reject) => {
    let usersUrl =
      store.state.webServicesBaseUrl + `/Users/admin/settings/${encodeURIComponent(customerId)}/${encodeURIComponent(userId)}`;
    axios
      .get(usersUrl, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function updateUserSettings(userId, customerId, settings){
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + `/Users/admin/settings/${encodeURIComponent(customerId)}/${encodeURIComponent(userId)}`

    axios
      .put(endpoint, settings, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function changeUserStatus(userId,status){
  return new Promise((resolve, reject) => {
    let endpoint = store.state.webServicesBaseUrl + `/Users/admin/changeStatus/${encodeURIComponent(store.state.customerId)}/${encodeURIComponent(userId)}`

    axios
      .put(endpoint, {status:status}, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        Vue.$log.error(" error: " + err.message);
        reject(err);
      });
  });
}
async function fetchUserSignature(userId,customerId){
  return new Promise((resolve,reject)=>{
    let endpoint = store.state.webServicesBaseUrl+`/Users/admin/signature/${encodeURIComponent(customerId)}/${encodeURIComponent(userId)}`
    axios
      .get(endpoint, {
        headers: {
          Authorization: "Bearer " + store.state.keycloak.token,
          "Content-Type": "application/json",
        },
        responseType: 'arraybuffer'
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  })
}
async function updateUserSignature(userId, customerId, signatureImageAsBlob, filename) {
  return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('signatureImage', signatureImageAsBlob, filename)
      let endpoint = store.state.webServicesBaseUrl+`/Users/admin/signature/${encodeURIComponent(customerId)}/${encodeURIComponent(userId)}`
      axios
        .put(endpoint,formData ,{
          headers: {
            Authorization: "Bearer " + store.state.keycloak.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
  })
}

export default {
  DEFAULT_WORKLIST_SETTINGS,
  
  // Helper methods
  generatePassword,
  
  // Webservice
  fetchAllUsers,
  fetchAdminTenants,
  createUser,
  deleteUser,
  fetchUserById,
  updateUser,
  resetPwd,
  fetchUserSettings,
  updateUserSettings,
  changeUserStatus,
  fetchUserSignature,
  updateUserSignature,
  getUserIds
};
